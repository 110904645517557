.tab-content {
    background: #fff;
    border: 1px solid #cfd8dc;
    padding: 20px;
}

.nav-tabs .nav-link.active {
    color: #263238;
    background: #fff;
    border-color: #cfd8dc;
    border-bottom-color: #fff;
}

.nav-tabs .active {
    color: #263238;
    background: #fff;
    border-color: #cfd8dc;
    border-bottom-color: #fff;
}

.nav-tabs > li {
    margin-bottom: -2px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 6px 11px;
    vertical-align: middle;
}

.table > thead > tr > th,
.table > thead > tr > td {
    font-weight: bold;
    font-size: 14px;
}

.form-control-low {
    height: 35px;
}

.navx > li > a {
    padding: 3px;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: #dddcd8;
}

.hr_divider {
    border-color: rgba(63, 58, 52, 0.27);
    margin: 10px 15px;
}

.nav-tabs > li > a {
    margin-right: 0px;
}

.table-compact > thead > tr > th,
.table-compact > tbody > tr > th,
.table-compact > tfoot > tr > th,
.table-compact > thead > tr > td,
.table-compact > tbody > tr > td,
.table-compact > tfoot > tr > td {
    padding: 2px 7px;
    vertical-align: top;
}

.easypielabel {
    width: 110px;
    text-align: center;
    position: relative;
    z-index: auto;
    top: -85px;
    float: left;
    display: block;
}

.inner {
    position: absolute;
    top: 0;
    left: 0;
}

.percent {
    display: inline-block;
    font-size: 1.5em;
    height: 160px;
    line-height: 160px;
    position: relative;
    text-align: center;
    width: 160px;
}

.vis-item {
    font-size: 12px;
}

.sebm-google-map-container {
    height: 300px;
}

.btn-simple {
    border: 0;
    padding: 0;
}

.selectx {
    padding-right: 30px;
    background: url("../img/br_down.png") no-repeat #F3F2EE right;
    appearance: none;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
}

.select-btn {
    border-radius: 2px;
    box-sizing: border-box;
    border-width: 1px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding: 7px 18px;
    border-color: #e8e7e3;
    color: #494541;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
}

.select-btn-grey {
    background-color: #f3f2ee;
}

.select-btn:hover, .select-btn:focus, .select-btn:active, .select-btn:active:focus, .select-btn:active:hover,
.open > .btn.dropdown-toggle, .open > .btn.dropdown-toggle:focus, .open > .btn.dropdown-toggle:hover {
    background-color: #d9d9d9;
    color: #494541;
    border-color: #3f3a34;
}

.dropdown-menu > li > a:hover {
    background-color: #d9d9d9;
    color: #494541;
}

.col-form-label {
    width: 100%;
}

.va-top {
    vertical-align: top;
}

.grayish {
    background-color: #eee;
}

.greenish {
    background-color: #bceebb;
}

.redish {
    background-color: #eeaebc;
}

.red {
    background-color: #ee676c;
}

.yellowish {
    background-color: #eeeb93;
}

a {
    cursor: pointer;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.img-rotated {
    -webkit-transform: rotate(180deg); /* Chrome and other webkit browsers */
    -moz-transform: rotate(180deg); /* FF */
    -o-transform: rotate(180deg); /* Opera */
    -ms-transform: rotate(180deg); /* IE9 */
    transform: rotate(180deg); /* W3C compliant browsers */

    /* IE8 and below */
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand');
}

.header-primary {
    color: #7f5cff;
}

[data-toggle="collapse"].collapsed .if-not-collapsed {
    display: none;
}

[data-toggle="collapse"]:not(.collapsed) .if-collapsed {
    display: none;
}

.columnList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*flex-wrap: wrap;*/
    /*height: 4em;*/
}

.m0 {
    margin: 0;
}

.mb5 {
    margin-bottom: 5px;
}

.ml5 {
    margin-left: 5px;
}

.p5 {
    padding: 5px;
}

.pl5 {
    padding-left: 5px;
}

.pr5 {
    padding-right: 5px;
}

.pr0 {
    padding-right: 0;
}

.pl0 {
    padding-left: 0;
}

.pt5 {
    padding-top: 5px;
}

.pb5 {
    padding-bottom: 5px;
}

.simple-notification-wrapper {
    z-index: 10000000;
}

.thumb-img {
    object-fit: cover;
    overflow: hidden;
    height: 128px;
    width: 128px;
}

.thumb-img-selected {
    object-fit: cover;
    overflow: hidden;
    height: 128px;
    width: 128px;
    background-color: #7f5cff;
}
